*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

.container {
  max-width: 1200px;
  width: calc(100vw - 4rem);
  margin: 2rem auto;
}

button {
  font-size: inherit;
  font-family: inherit;
}

.error-msg {
  color: hsl(0, 100%, 67%);
}

.comments-title {
  margin-bottom: 0.5rem;
}

.comment-form-row {
  display: flex;
  gap: 0.5rem;
}

.message-input {
  flex-grow: 1;
  resize: none;
  height: 70px;
  border-radius: 0.5em;
  padding: 0.5em;
  font-size: inherit;
  font-family: inherit;
  border: 2px solid hsl(235, 50%, 74%);
  line-height: 1.4;
}

.message-input:focus {
  border-color: hsl(235, 100%, 67%);
  outline: none;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.comment-stack {
  margin: 0.5rem 0;
}

.comment-stack:last-child {
  margin-bottom: 0;
}

.nested-comments {
  padding-left: 0.5rem;
  flex-grow: 1;
}

.nested-comments-stack {
  display: flex;
}

.collapse-line {
  border: none;
  background: none;
  padding: 0;
  width: 15px;
  margin-top: 0.5rem;
  position: relative;
  cursor: pointer;
  outline: none;
  transform: translateX(-50%);
}

.collapse-line:hover::before,
.collapse-line:focus-visible::before {
  background-color: hsl(235, 100%, 60%);
}

.collapse-line::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 1px;
  background-color: hsl(235, 50%, 74%);
  transition: background-color 100ms ease-in-out;
}

.hide {
  display: none;
}

.btn {
  --hue: 235;
  --color: hsl(var(--hue), 100%, 67%);
  padding: 0.5em 1em;
  background: var(--color);
  border: none;
  color: white;
  border-radius: 0.5em;
  font-size: 0.75em;
  cursor: pointer;
}

.btn:hover,
.btn:focus-visible {
  --color: hsl(var(--hue), 100%, 74%);
}

.btn.icon-btn {
  background: none;
  color: var(--color);
  padding: 0.25em;
  display: flex;
  align-items: center;
}

.mr-1 {
  margin-right: 0.25em;
}

.icon-btn:hover,
.icon-btn:focus-visible {
  --color: hsl(var(--hue), 100%, 74%);
}

.icon-btn-active,
.icon-btn.danger {
  --hue: 0;
}

.icon-btn-active {
  position: relative;
}

.icon-btn-active::before {
  content: "\00D7";
  position: absolute;
  font-size: 0.75em;
  width: 1em;
  height: 1em;
  color: white;
  background-color: var(--color);
  border-radius: 50%;
  bottom: 0.1em;
  right: 0.1em;
}

.comment {
  padding: 0.5rem;
  border: 1px solid hsl(235, 100%, 90%);
  border-radius: 0.5rem;
}

.comment .header {
  color: hsl(235, 50%, 67%);
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  font-size: 0.75em;
}

.comment .header .name {
  font-weight: bold;
}

.comment .message {
  white-space: pre-wrap;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.comment .footer {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.ml-3 {
  margin-left: 1.5rem;
}

.btn[disabled] {
  --color: hsl(var(--hue), 20%, 74%);
}
